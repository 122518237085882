<!--
 * @Author: 刘锦
 * @Date: 2020-10-16 16:03:20
 * @LastEditTime: 2022-03-25 14:23:52
 * @LastEditors: Seven
 * @Description: 
 
 * @FilePath: \workbench-front\src\views\workbench\order\after-sale\detail\index.vue
-->
<template>
  <div class="after-sale-detail">
    <div class="section-box sale-summary">
      <div class="section-content">
        <div class="sub-title">售后概况</div>
        <!-- 售后概况 -->
        <overview-info :aftersaleType="aftersaleType" :pageData="pageData"/>
        <!-- 售后流程 -->
        <process :pageData="pageData" :saleSummaryTable="saleSummaryTable" @showReviewRecord="showReviewRecord"/>
        <!-- 出纳审核人及申请原因 -->
        <cashier-info :pageData="pageData"/>
        <!-- 附件 -->
        <annex :aftersaleAttachList="pageData.aftersaleAttachList" v-if="pageData.aftersaleAttachList && !!pageData.aftersaleAttachList.length"/>
      </div>
      <div class="section-line"></div>
    </div>
    <!-- 售后订单 -->
    <aftersale-order-vo :saleOrderTable="saleOrderTable" @goDetail="goDetail" :pageData="pageData"/>
    <!-- 升班或转班订单表格 -->
    <new-order v-if="showUpgradeOrTransfer" :aftersaleType="aftersaleType" :newOrder="lastTable" @goDetail="goDetail"/>
    <!-- 付款信息表格 -->
    <refund-info 
      v-show="aftersaleType != aftersaleTypeEnum.upgrade" 
      :aftersaleType="aftersaleType" 
      :droptOutInfoTable="droptOutInfoTable" 
      :showXuebank="showXuebank"
      :showXueName="showXueName"
      @showPayinfoModal="showPayinfoModal"
    />
    <!-- 付款项目 -->
    <payments 
      :aftersaleType="aftersaleType" 
      :pageData="pageData" 
      :aftersalePay="aftersalePay"
      :aftersalePayStatus="aftersalePayStatus" 
      :sumRefundMoney="sumRefundMoney"
      @toggleAfterpayTable="toggleAfterpayTable"
    />

    <div class="action-btns">
      <el-button style="width: 120px" size="large" @click="$router.back()">返回</el-button>
    </div>
    <drop-payinfo
      ref="dropDrawer"
      :visible.sync="payinfoModalVisible"
      :columns="payinfoColumns"
      :dropColumns="dropColumns"
      :tableData="payinfoTable"
      :extraInfo="extraInfo"
      :titleList="mainGoodsInfoTable"
      :moneyInfo="orderInfoTable"
    />
    <review-history 
      :visible="onReviewHistoryStatus"
      :reviewData="reviewData"
      ref="drawer"
      @on-cancel="onReviewHistoryCancel"
    />
  </div>
</template>

<script>
import {
  processTypeMap,
  processSaleMap,
  orderStatusMap,
  afterSaleMap,
  afterSaleStatusMap,
  allPayMap,
  orderTypeMap,
  refundTypeMap,
  refundMap,
  installmentTypeEnum,
  aftersaleTypeEnum,
  goodsTemplateEnum
} from "../../constants"
import { openNewWindow } from "@/utils"
import { accAdd } from "@/utils/mathMethod"
// import auth from "@/utils/auth"
export default {
  name: "AfterSaleDetail",
  data() {
    return {
      orderId: '',
      columns: [
        { label: "付款笔数", prop: "payNo" },
        { label: "付款时间", prop: "refundTime", width: "160px" },
        { label: "付款金额", prop: "refundMoney" },
        { label: "付款类型", prop: "refundType" },
        { label: "付款方式", prop: "payType" },
        { label: "学员姓名", prop: "nickName" },
        { label: "学员账号", prop: "balanceAccount" },
        { label: "学员开户名", prop: "bankAccountName" },
        { label: "学员户行账号", prop: "bankAccount" },
        { label: "学员开户行", prop: "bank" },
        { label: "付款", prop: "refundProcess" },
      ],
      dropColumns: [
        { label: "收支项目编码", prop: "code",fixed:true },
        { label: "收支项目名称", prop: "orderInoutProjectName", fixed:true },
        { label: "收支占比", prop: "inoutProportion" },
        { label: "应收金额", prop: "receivableMoney" },
        { label: "已收金额", prop: "receivedMoney",  },
        { label: "扣费金额", prop: "deductionMoney", width: '120px' },
        { label: "核定可退金额", prop: "refundableMoney" },
        { label: "已退金额", prop: "refundedMoneySum" },
        { label: "绩效比例", prop: "performanceRatio" },
        { label: "完成率比例", prop: "completionRatio" },
        { label: "收支项目类型", prop: "type" },
        { label: "是否计算绩效", prop: "calculatePerformance" },
        { label: "计算收入方式", prop: "periodIncome", width: "160px" },
      ],
      classInfo: [],
      breadcrumbs: [{ title: "订单售后管理", path: "/aftersale/list" }, { title: "售后详情" }],
      pageData: {},
      //退费计算表格
      aftersalePay: [],
      aftersalePayStatus: [],//记录表格展开缩起状态
      saleSummaryTable: [],
      // 付款信息表格
      droptOutInfoTable: [],
      // 售后订单表格
      saleOrderTable: [],

      // 转班或升班订单
      lastTable: [],
      processTypeMap,
      processSaleMap,
      orderStatusMap,
      afterSaleMap,
      afterSaleStatusMap,
      allPayMap,
      orderTypeMap,
      showMoney: true,
      spanArrTable: [3, 0, 0, 2, 0, 2, 0, 1], //退费计算得单元格合并
      refundTypeMap,
      refundMap,
      payinfoTable: {},
      payinfoModalVisible: false,
      payinfoColumns: [
        { label: "付款笔数", prop: "payNo", fixed: true },
        { label: "付款时间", prop: "refundTime", width: "160px", fixed: true },
        { label: "付款金额", prop: "refundMoney" },
        { label: "降贷金额", prop: "reduceLoanMoney" },
        { label: "付款类型", prop: "refundTypeText" },
        { label: "付款方式", prop: "payTypeText" },
        { label: "退贷类型", prop: "returnLoanTypeText" },
        { label: "预计退贷日期", prop: "loanRefundTime" },
        { label: "学员已还金额", prop: "loanReturnedMoney" },
        { label: "贷款平台", prop: "installmentType" },
        { label: "平台开户名", prop: "loanCompanyName" },
        { label: "平台开户行", prop: "loanBankName" },
        { label: "平台银行账号", prop: "loanBankAccount" },
        { label: "学员姓名", prop: "nickName" },
        { label: "学员账号", prop: "balanceAccount" },
        { label: "学员开户名", prop: "bankAccountName" },
        { label: "学员户行账号", prop: "bankAccount" },
        { label: "学员开户行", prop: "bank" },
        { label: "EAS单号", prop: "easNumber" },
        { label: "付款", prop: "refundProcess" },
      ],
      extraInfo: {},
      orderInfoTable: [],
      mainGoodsInfoTable: [],
      showXuebank: false, //判断是否显示学员开户名
      showXueName: false,//判断是否显示学员姓名
      installmentTypeEnum,
      aftersaleTypeEnum,
      goodsTemplateEnum,
      showHistoryAuditRecordVisible: false,
      onReviewHistoryStatus: false,
      reviewData: {},
    }
  },
  components: {
    dropPayinfo: () => import("../../components/drop-payinfo"),
    process:()=> import('./components/process.vue'),
    overviewInfo: ()=> import('./components/overviewInfo.vue'),
    cashierInfo: ()=> import('./components/cashierInfo.vue'),
    aftersaleOrderVo: ()=>import('./components/aftersaleOrderVo.vue'),
    newOrder: ()=>import('./components/newOrder.vue'),
    refundInfo: ()=>import('./components/refundInfo.vue'),
    payments: ()=>import('./components/payments.vue'),
    annex: ()=>import('./components/annex.vue'),
    reviewHistory: ()=>import('../../components/reviewHistory.vue'),
  },
  methods: {
    goDetail(row) {
      let path = `${this.pageData.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";  
      openNewWindow(`${path}?orderId=${row.orderId}&status=${row.orderStatus}`)
    },
    goDetailById(pageData, type) {
      let order = type == "new" ? pageData.newOrder : pageData.aftersaleOrderVo[0]
      let orderId = order.orderId
      this.orderId = order.orderId || "";
      let path = `${pageData.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: orderId,
          status: order.orderStatus
        }
      }); 
    },
    findHeDing(itemList) {
      let num = 0
      for (let it of itemList) {
        if(it.inoutProjects && it.inoutProjects.length){
          for (let j of it.inoutProjects) {
            num = accAdd(num, j.refundableMoney)
          }
        }
      }
      console.log("核定", num)
      return num
    },
    // 获取售后单详情数据
    async getPageData() {          
      const params = {
        id: this.$route.query.id,
      }
      this.aftersaleId = this.$route.query.id;
      params.channel = 0
      const { data={}, code } = await this.$fetch("third_getAfterSaleDetail", params)
      if( code != 200 ){
        return;
      }


     console.log("售后页面data", data)
      let aftersaleOrderVo = data.aftersaleOrderVo || [];
      let orderDetail = aftersaleOrderVo[0] || {};
      this.orderId = orderDetail.orderId;
      let num
      if (data.aftersalePay && data.aftersalePay) {
        num = this.findHeDing(data.aftersalePay) || 0
      }
      this.pageData = data
      if( this.pageData.updateTime ){
        this.pageData.updateTime = this.$dayjs(this.pageData.updateTime).format("YYYY-MM-DD HH:mm:ss")
      }
      this.pageData.refundMoney = num ? num : 0
      let processRecored = data.processRecored || []
      let j = 1
      for (var i = 0; i < processRecored.length; i++) {
        if (i > 0 && processRecored[i].process !== processRecored[i - 1].process) j++
        processRecored[i].index = j
      }
      this.saleSummaryTable = processRecored
      this.saleOrderTable = data.aftersaleOrderVo
      this.droptOutInfoTable = data.refundInfo || [] //替换付款信息的字段
      console.log('退费', this.droptOutInfoTable);
      // 如果付款信息表格只有一条的情况下，就判断退费方式是否是学员余额还是银行，0学员余额 1银行
        if (this.droptOutInfoTable.length == 1) {
          this.droptOutInfoTable.map((item) => {
            if (item.payType !== 0) {
              this.showXuebank = true
              this.showXueName = false
              if (!item.bankAccountName) {
                item.bankAccountName = "- -"
              }
              if (!item.bank) {
                item.bank = "- -"
              }
              if (!item.bankAccount) {
                item.bankAccount = "- -"
              }
            } else {
              this.showXueName = true
              this.showXuebank = false
            }
            console.log('showXuebank 只有一条的情况下', this.showXuebank);
          })
       } else if(this.droptOutInfoTable.length >= 2) {
         //如果付款信息表格有2条或2条以上的话，就判断当前列
         this.droptOutInfoTable.map((item) => {
            this.showXuebank = true
            this.showXueName = true
            if (!item.bankAccountName) {
              item.bankAccountName = "- -"
            }
            if (!item.bank) {
              item.bank = "- -"
            }
            if (!item.bankAccount) {
              item.bankAccount = "- -"
            }
          })
       }
      

      //lzl-报名新增转班，升班报名，转班新增场景，招生老师取做单老师
      if (data.newOrder) data.newOrder.teacherRecruitName = data.creater

      this.lastTable = data.newOrder ? [data.newOrder] : []
      let aftersalePayStatus = []

      for (let i in data.aftersalePay) {
        data.aftersalePay[i].openStatus = true
        // 默认首栏展开
        aftersalePayStatus.push({expand: i == 0})
        if (i == 0) {
          data.aftersalePay[i].openStatus = true
        } else {
          data.aftersalePay[i].openStatus = false
        }
      }
      this.aftersalePay = data.aftersalePay
      // 记录每栏状态
      this.aftersalePayStatus = aftersalePayStatus

    },
    // 跳转订单详情
    goOrderDetail(row) {
      this.$router.push(`/workbench/order/management/detail?id=${row.orderId}`)
    },
    async showPayinfoModal(row) {
      console.log("rrrow", row)
      const params = {
        id: this.$route.query.id,
      }
      const { data } = await this.$fetch("findPaymentDetail", params)
      this.payinfoTable = data
      this.payinfoModalVisible = true
      this.$refs.dropDrawer.init()
    },
    toggleAfterpayTable(index){
      this.$set(this.aftersalePayStatus[index], 'expand', !this.aftersalePayStatus[index].expand)
    },
    showReviewRecord(row){
      this.reviewData = {id: this.$route.query.id}
      this.$refs.drawer.init(this.reviewData)
    },
    //审核记录返回按钮
    onReviewHistoryCancel() {
      this.onReviewHistoryStatus = false;
    },
  },
  mounted() {
    this.getPageData()
    if (this.$route.query.type == 2) {
      this.showMoney = true
    } else {
      this.showMoney = false
    }
  },
  computed: {
    showUpgradeOrTransfer() {
      return this.aftersaleType != aftersaleTypeEnum.dropout
    },
    aftersaleType() {
      return this.$route.query.type
    },
    sumRefundMoney() {
      let sum = 0
      if (this.aftersalePay) {
        for (let i of this.aftersalePay) {
          sum = accAdd(sum, i.refundMoney)
        }
      }
      return sum
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
